// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-datenschutz-js": () => import("./../../../src/pages/legal/datenschutz.js" /* webpackChunkName: "component---src-pages-legal-datenschutz-js" */),
  "component---src-pages-legal-impressum-js": () => import("./../../../src/pages/legal/impressum.js" /* webpackChunkName: "component---src-pages-legal-impressum-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

